import {doGetAxios, doPostAxios} from './axios';

export const apiPortal = {
  loadListInvestment: async (params: {
    pagination?: {
      currentPage?: number;
      itemsPerPage?: number;
      sort?: any;
      totalItemCount?: number;
    };
    queries?: {sourceCode?: string; investorName?: string};
  }) => {
    const obj = {
      pagination: {
        currentPage: 1,
        itemsPerpage: 10,
        sort: {},
        totalItemCount: 10,
      },
      queries: {
        sourceCode: '',
        investorName: '',
      },
    };
    const url = `investment/account/list`;
    return doPostAxios(url, {...obj, ...params});
  },
  loadListTransactionFee: async (params: {
    pagination: {
      currentPage: number;
      itemsPerPage: number;
      sort: any;
      totalItemCount: number;
    };
    queries: {fromDate: string; toDate: string};
  }) => {
    const url = `tracking/export-buy-order-by-branch`;
    return doPostAxios(url, params);
  },
  loadListAUMFee: async (params: {
    pagination: {
      currentPage: number;
      itemsPerPage: number;
      sort: any;
      totalItemCount: number;
    };
    queries: {fromDate: string; toDate: string};
  }) => {
    const url = `tracking/export-aum-by-branch`;
    return doPostAxios(url, params);
  },

  loadInvestmentDetails: async (params: {
    idNoOrInvestmentNumber: string | number;
  }) => {
    const url = `investment/account/load-by-source`;
    return doPostAxios(url, params);
  },
  loadListProduct: async () => {
    const url = `product/active-list`;
    return doGetAxios(url);
  },
  loadProductDetals: async (params: {id: string | number}) => {
    const url = `product-program/list?productId=${params.id}`;
    return doGetAxios(url);
  },
  loadAmount: async (params: {
    fromDate: string;
    toDate: string;
    sourceCode: string;
  }) => {
    const url = 'dashboard/fund/amount-load';
    return doPostAxios(url, params);
  },
  loadTableAsset: async (params: {
    pagination: {
      currentPage: number;
      itemsPerPage: number;
    };
    queries: {
      productId: number;
    };
  }) => {
    const url = 'asset-management/table';
    return doPostAxios(url, params);
  },
  getBanner: (params?: {}) => {
    const url = `banner/load`;
    return null;
    // return doGetAxios(url);
  },
};
