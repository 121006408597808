import {createSlice} from '@reduxjs/toolkit';
import {
  loadAmountDashboard,
  loadInvestmentDetails,
  loadListAUMFee,
  loadListInvestment,
  loadListProduct,
  loadListTransactionFee,
} from './action';

interface IinitialState {
  listInvestment: Array<any>;
  loadingListInvestment: boolean;
  paramsInvestment: any;
  //
  loadingInvestmentDetails: boolean;
  investmentDetails: any;
  //
  listProduct: Array<any>;
  //
  loadingAmountDashboard: boolean;
  amountDashboard: any;
  productInfo: Array<any>;
  //
  listAUMFee: Array<any>;
  paramsAUMFee: any;
  loadingListAUMFee: boolean;
  //
  listTransactionFee: Array<any>;
  paramsTransactionFee: any;
  loadingListTransactionFee: boolean;
  //
}

const initialState = {
  loadingListInvestment: false,
  loadingInvestmentDetails: false,
  listInvestment: [],
  listProduct: [],
  listTransactionFee: [],
  listAUMFee: [],
  paramsInvestment: {},
  paramsAUMFee: {},
  paramsTransactionFee: {},
  investmentDetails: {},
  //
  amountDashboard: {},
  productInfo: [],
  loadingListTransactionFee: false,
  loadingListAUMFee: false,
  loadingAmountDashboard: false,
} as IinitialState;

const investment = createSlice({
  name: 'investment',
  initialState: initialState,
  reducers: {
    // loadListInvestment(state, action: PayloadAction<any>) {
    //   state.listInvestment = action.payload;
    // },
  },
  extraReducers: builder => {
    // //load morehistore
    builder.addCase(loadListInvestment.pending, (state, action) => {
      state.loadingListInvestment = true;
    });
    builder.addCase(loadListInvestment.fulfilled, (state, action) => {
      state.loadingListInvestment = false;
      state.listInvestment = action.payload.items;
      state.paramsInvestment = action.payload;
    });
    builder.addCase(loadListInvestment.rejected, (state, action) => {
      state.loadingListInvestment = false;
    });
    //
    builder.addCase(loadInvestmentDetails.pending, (state, action) => {
      state.loadingInvestmentDetails = true;
    });
    builder.addCase(loadInvestmentDetails.fulfilled, (state, action) => {
      state.loadingInvestmentDetails = false;
      state.investmentDetails = action.payload;
    });
    builder.addCase(loadInvestmentDetails.rejected, (state, action) => {
      state.loadingInvestmentDetails = false;
    });
    //
    builder.addCase(loadListProduct.pending, (state, action) => {});
    builder.addCase(loadListProduct.fulfilled, (state, action) => {
      state.listProduct = [
        {
          id: 0,
          name: 'Chọn tất cả',
          nameEn: 'Chọn tất cả',
          code: '',
        },
        ...action.payload,
      ];
    });
    builder.addCase(loadListProduct.rejected, (state, action) => {});
    //
    builder.addCase(loadAmountDashboard.pending, (state, action) => {
      state.loadingAmountDashboard = true;
    });
    builder.addCase(loadAmountDashboard.fulfilled, (state, action) => {
      state.loadingAmountDashboard = false;
      state.amountDashboard = action.payload.total;
      state.productInfo = action.payload.productInfo;
    });
    builder.addCase(loadAmountDashboard.rejected, (state, action) => {
      state.loadingAmountDashboard = false;
    });
    //
    builder.addCase(loadListAUMFee.pending, (state, action) => {
      state.loadingListAUMFee = true;
    });
    builder.addCase(loadListAUMFee.fulfilled, (state, action) => {
      state.loadingListAUMFee = false;
      state.listAUMFee = action.payload.items;
      state.paramsAUMFee = action.payload;
    });
    builder.addCase(loadListAUMFee.rejected, (state, action) => {
      state.loadingListAUMFee = false;
    });
    //
    builder.addCase(loadListTransactionFee.pending, (state, action) => {
      state.loadingListTransactionFee = true;
    });
    builder.addCase(loadListTransactionFee.fulfilled, (state, action) => {
      state.loadingListTransactionFee = false;
      state.listTransactionFee = action.payload.items;
      state.paramsTransactionFee = action.payload;
    });
    builder.addCase(loadListTransactionFee.rejected, (state, action) => {
      state.loadingListTransactionFee = false;
    });
    // //load morehistore
    // builder.addCase(focusProduct.pending, (state, action) => {
    //   state.loadingFocus = true;
    // });
    // builder.addCase(focusProduct.fulfilled, (state, action) => {
    //   state.productDetails[action.payload.id] = action.payload;
    //   state.loadingFocus = false;
    // });
    // builder.addCase(focusProduct.rejected, (state, action) => {
    //   state.loadingFocus = false;
    // });
    // //load morehistore
    // builder.addCase(getDataNav.pending, (state, action) => {
    //   // state.loadingFocus = true;
    //   state.loadingNav = true;
    // });
    // builder.addCase(getDataNav.fulfilled, (state, action) => {
    //   // state.productDetails[action.payload.id] = action.payload;
    //   state.loadingNav = false;
    //   state.dataNav[state.idFocus] = action.payload;
    // });
    // builder.addCase(getDataNav.rejected, (state, action) => {
    //   state.loadingNav = false;
    // });
  },
});
const {actions, reducer} = investment;
export const {} = actions;
export default reducer;
