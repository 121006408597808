import {createAsyncThunk} from '@reduxjs/toolkit';
import {apiPortal} from 'services';

export const loadListInvestment = createAsyncThunk(
  `listInvestMent@/load`,
  async (
    params: {
      pagination?: {
        currentPage?: number;
        itemsPerPage?: number;
        sort?: any;
        totalItemCount?: number;
      };
      queries?: {sourceCode?: string; investorName?: string};
    },
    {rejectWithValue, dispatch},
  ) => {
    try {
      const res = await apiPortal.loadListInvestment(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadListAUMFee = createAsyncThunk(
  `listAUMFee@/load`,
  async (params: any, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiPortal.loadListAUMFee(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadListTransactionFee = createAsyncThunk(
  `listTransactionFee@/load`,
  async (params: any, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiPortal.loadListTransactionFee(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadInvestmentDetails = createAsyncThunk(
  `investmentDetails@/load`,
  async (
    params: {
      idNoOrInvestmentNumber: string;
    },
    {rejectWithValue, dispatch},
  ) => {
    try {
      const res = await apiPortal.loadInvestmentDetails(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadListProduct = createAsyncThunk(
  `listProduct@/load`,
  async (params: {}, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiPortal.loadListProduct();
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadAmountDashboard = createAsyncThunk(
  `amountDashboard@/load`,
  async (
    params: {
      fromDate: string;
      toDate: string;
      sourceCode: string;
    },
    {rejectWithValue, dispatch},
  ) => {
    try {
      const res = await apiPortal.loadAmount(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
